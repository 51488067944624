import './Schedule.css';

interface GetEventSlugProps {
  id: string;
  ongoing?: boolean;
  start: Date;
}

export const getEventSlug = (event?: GetEventSlugProps) =>
  event
    ? [event.id, event.ongoing ? event.start.valueOf() : undefined]
        .filter(Boolean)
        .join('.')
    : undefined;
