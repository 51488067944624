import { RawProduct } from '@shared/models/products';
import { doc, updateDoc } from 'firebase/firestore';
import { useCallback } from 'react';

import { useUser } from '@features/Organization/organizationSlice';
import { useMutation } from '@models/mutations/useMutation';
import { productsCollection } from '@models/products/model';
import { serverTimestamp } from '@utils/serverTimestamp';

export const useUpdateProduct = (productId: string | undefined) => {
  const user = useUser();
  const userId = user?.userId;
  const [migrateProductsMutation] = useMutation('migrateProducts');

  return useCallback(
    async (product: Partial<RawProduct>) => {
      const result = await updateDoc(doc(productsCollection, productId), {
        updatedAt: serverTimestamp(),
        updatedBy: userId,
        ...product,
      });

      await migrateProductsMutation({
        type: 'global',
        globalFields: Object.keys(product),
      });

      return result;
    },
    [productId, userId, migrateProductsMutation]
  );
};
