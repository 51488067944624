import { Box } from '@mui/joy';

import { LoadingSpinner } from './LoadingSpinner';

export const RouteLoader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <LoadingSpinner />
    </Box>
  );
};
