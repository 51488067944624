import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { usePrevious } from 'react-use';

export type UseQueryParam = (param: string) => string | null;

const getValue = (search: string, param: string) =>
  new URLSearchParams(search).get(param);

export const useSearchParam: UseQueryParam = (param) => {
  const windowLocation = window.location;
  const [value, setValue] = useState<string | null>(() =>
    getValue(windowLocation.search, param)
  );

  const location = useLocation();
  const locationString = useMemo(() => JSON.stringify(location), [location]);
  const previousLocationString = usePrevious(locationString);

  useEffect(() => {
    if (locationString === previousLocationString) {
      return;
    }

    setValue(getValue(windowLocation.search, param));
  }, [locationString, previousLocationString, param, windowLocation.search]);

  return value;
};
