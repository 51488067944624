import { useRef } from 'react';

import { useOrgainzation } from '@features/Organization/organizationSlice';
import { useProductQuery } from '@models/products/useProductQuery';

import { useUpdateProduct } from '../useUpdateProduct';

export interface UseSettingsProviderValueProps {
  page?: React.MutableRefObject<HTMLElement | null>;
}

export const useSettingsProviderValue = ({
  page: externalPage,
}: UseSettingsProviderValueProps) => {
  const page = useRef<HTMLElement | null>(null);
  const globalProductId = useOrgainzation()?.globalProductId;
  const [product] = useProductQuery(globalProductId);
  const updateProduct = useUpdateProduct(globalProductId);

  return {
    page: externalPage ?? page,
    globalProductId,
    product,
    updateProduct,
  };
};

export type UseSettingsProviderReturn = ReturnType<
  typeof useSettingsProviderValue
>;
