import {
  IonApp,
  createAnimation,
  iosTransitionAnimation,
  isPlatform,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Providers from '@components/Providers';
import { RouteLoader } from '@components/RouteLoader';
import { UnauthenticatedInvoice } from '@features/Admin/Invoices/InvoiceDetail/UnauthenticatedInvoice';
import LoginOrSignUp from '@features/LoginOrSignUp';
import { AlmostDone } from '@features/LoginOrSignUp/AlmostDone';
import { InviteExpired } from '@features/LoginOrSignUp/InviteExpired';
import '@theme/variables.css';

import AuthenticatedRoutes from './AuthenticatedRoutes';

const skipIonicSwipeBack = isPlatform('ios') && isPlatform('hybrid');

setupIonicReact({
  rippleEffect: false,
  mode: 'ios',
  ...(skipIonicSwipeBack
    ? {
        swipeBackEnabled: false,
        navAnimation: (baseEl, opts) => {
          if (opts.direction === 'back') {
            return createAnimation();
          }

          return iosTransitionAnimation(baseEl, opts);
        },
      }
    : undefined),
});

const App = () => (
  <IonApp>
    <Suspense fallback={<RouteLoader />}>
      <IonReactRouter>
        <Providers>
          <Switch>
            <Redirect path="/" exact to="/login" />
            <Route path="/login" exact>
              <LoginOrSignUp variant="login" />
            </Route>
            <Route path="/sign-up" exact>
              <LoginOrSignUp variant="signUp" />
            </Route>
            <Route path="/almost-done" exact>
              <AlmostDone />
            </Route>
            <Route path="/invite-expired" exact>
              <InviteExpired />
            </Route>
            <Route path="/:organizationSlug">
              <AuthenticatedRoutes />
            </Route>
            <Route path="/invoices/:invoiceId" exact>
              <UnauthenticatedInvoice />
            </Route>
          </Switch>
        </Providers>
      </IonReactRouter>
    </Suspense>
  </IonApp>
);

export default App;
