import { keyframes } from '@emotion/react';
import { Box } from '@mui/joy';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface LoadingSpinnerProps {
  size?: number;
}

export const LoadingSpinner = ({ size = 0.5 }: LoadingSpinnerProps) => {
  return (
    <Box
      sx={{
        width: `${225 * size}px`,
        height: `${225 * size}px`,
        display: 'inline-block',
        overflow: 'hidden',
        background: '#ffffff',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          transform: 'translateZ(0) scale(1)',
          backfaceVisibility: 'hidden',
          transformOrigin: '0 0',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            animation: `${rotate} 1s linear infinite`,
            width: `${180 * size}px`,
            height: `${180 * size}px`,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
            borderRadius: '50%',
            boxShadow: '0 4.725px 0 0 #cccccc',
          }}
        />
      </Box>
    </Box>
  );
};
