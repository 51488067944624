import styled from '@emotion/styled';
import { IonRouterLink } from '@ionic/react';

const IonicLinkBase = styled(IonRouterLink, {
  shouldForwardProp: (prop) => prop !== 'unstyled',
})<{ unstyled?: boolean }>(({ unstyled }) =>
  unstyled ? { textDecoration: 'none', color: 'initial' } : undefined
);

interface IonicLink2Props {
  children?: React.ReactNode;
  routerLinkProps?: React.ComponentProps<typeof IonRouterLink>;
  unstyled?: boolean;
  spanProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;
  dataTestId?: string;
}

export const IonicLink2 = ({
  routerLinkProps,
  children,
  unstyled,
  spanProps,
  dataTestId,
}: IonicLink2Props) => {
  if (!routerLinkProps) {
    return <span {...spanProps}>{children}</span>;
  }

  return (
    <IonicLinkBase
      unstyled={unstyled}
      data-testid={dataTestId}
      {...routerLinkProps}
    >
      {children}
    </IonicLinkBase>
  );
};
