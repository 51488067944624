import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  createGesture,
  useIonRouter,
} from '@ionic/react';
import { mailOutline } from 'ionicons/icons';
import { Fragment, Suspense, useEffect, useRef, useState } from 'react';

import { useFeatureFlags } from '@components/FeatureFlags/useFeatureFlags';
import IonItemNavLink from '@components/IonItemNavLink';
import { ListHeader } from '@components/ListHeader';
import ProfileAvatar from '@components/ProfileAvatar';
import { RouteLoader } from '@components/RouteLoader';
import { useUserData } from '@components/UserData/useUserData';
import { getContactName } from '@features/Contacts/ContactDetail/ViewContact';
import { useSettingsAppPageLists } from '@features/Settings2/useSettingsAppPageLists';
import { signOut } from '@utils/firebase';

import About from './About';
import FeatureFlags from './FeatureFlags';

interface SettingsOverviewProps {
  modalRef: React.MutableRefObject<HTMLIonModalElement | null>;
}

const SettingsOverview = ({ modalRef }: SettingsOverviewProps) => {
  const router = useIonRouter();
  const { userContact } = useUserData();
  const [
    {
      showFeatureFlagsEnabled,
      supportDocsEnabled,
      multiOrganizationSupport,
      allSettingsEnabled,
    },
  ] = useFeatureFlags();
  const [showFeatureFlagsOverride, setShowFeatureFlagsOverride] =
    useState(false);

  const settingsAppPageLists = useSettingsAppPageLists();

  const DOUBLE_CLICK_THRESHOLD = 500;
  const titleRef = useRef<HTMLIonCardElement | null>(null);

  useEffect(() => {
    if (titleRef.current) {
      let lastOnStart = 0;

      const gesture = createGesture({
        el: titleRef.current,
        threshold: 0,
        onStart: () => {
          const now = Date.now();

          if (Math.abs(now - lastOnStart) <= DOUBLE_CLICK_THRESHOLD) {
            setShowFeatureFlagsOverride((current) => !current);
          } else {
            lastOnStart = now;
          }
        },
        gestureName: 'double-click',
      });

      gesture.enable();
    }
  }, []);

  const onDone = () => modalRef.current?.dismiss();
  const primaryEmail = userContact?.emails?.find((email) => email.isPrimary);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle ref={titleRef}>Settings</IonTitle>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onDone}>
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" color="light">
        <IonList inset>
          <IonItem>
            <ProfileAvatar
              size="sm"
              sx={{
                width: '3.5rem',
                height: '3.5rem',
                margin: '0.5em',
                marginBottom: '0.5em',
                marginRight: '0.5em',
                marginLeft: 0,
              }}
            />
            <IonLabel>
              {userContact ? (
                <h3>{getContactName(userContact, undefined)}</h3>
              ) : null}
              {primaryEmail ? <p>{primaryEmail.address}</p> : null}
            </IonLabel>
          </IonItem>
        </IonList>

        {settingsAppPageLists.map((list, listIndex) => (
          <Fragment key={listIndex}>
            <ListHeader inset>{list.title}</ListHeader>
            <IonList inset>
              {list.items.map(({ title, component: Component }, itemIndex) => (
                <IonItemNavLink
                  key={itemIndex}
                  button
                  component={() => (
                    <>
                      <IonHeader>
                        <IonToolbar>
                          <IonButtons slot="start">
                            <IonBackButton color="dark" />
                          </IonButtons>
                          <IonTitle>{title}</IonTitle>
                          <IonButtons slot="end">
                            <IonButton color="dark" onClick={onDone}>
                              Done
                            </IonButton>
                          </IonButtons>
                        </IonToolbar>
                      </IonHeader>
                      <IonContent className="ion-padding" color="light">
                        <Suspense fallback={<RouteLoader />}>
                          {Component ? <Component /> : null}
                        </Suspense>
                      </IonContent>
                    </>
                  )}
                >
                  <IonLabel>{title}</IonLabel>
                </IonItemNavLink>
              ))}
            </IonList>
          </Fragment>
        ))}

        {multiOrganizationSupport ? (
          <IonList inset>
            <IonItem detail>
              <IonLabel>Create or Join a Workspace</IonLabel>
            </IonItem>
            <IonItem detail>
              <IonLabel>Add an Account</IonLabel>
            </IonItem>
          </IonList>
        ) : null}

        <IonList inset>
          <IonItem
            detail
            href="mailto:hello@adriancarriger.com"
            detailIcon={mailOutline}
          >
            <IonLabel>Send Feedback</IonLabel>
          </IonItem>
          {allSettingsEnabled ? (
            <IonItem detail>
              <IonLabel>Rate App</IonLabel>
            </IonItem>
          ) : null}
          {supportDocsEnabled ? (
            <IonItem detail>
              <IonLabel>Help Center</IonLabel>
            </IonItem>
          ) : null}
          <IonItemNavLink button component={() => <About onDone={onDone} />}>
            <IonLabel>About</IonLabel>
          </IonItemNavLink>
          {showFeatureFlagsEnabled || showFeatureFlagsOverride ? (
            <IonItemNavLink
              button
              component={() => <FeatureFlags onDone={onDone} />}
            >
              <IonLabel>Feature Flags</IonLabel>
            </IonItemNavLink>
          ) : null}
        </IonList>
        <IonList inset>
          <IonItem
            onClick={() => {
              signOut();
              router.push('/');
            }}
          >
            <IonLabel>Sign Out</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </>
  );
};

export default SettingsOverview;
