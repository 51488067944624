import { createContext } from 'react';

import {
  UseSettingsProviderReturn,
  UseSettingsProviderValueProps,
  useSettingsProviderValue,
} from './useSettingsProviderValue';

export const SettingsProviderContext = createContext<
  UseSettingsProviderReturn | undefined
>(undefined);

interface SettingsProviderProviderProps extends UseSettingsProviderValueProps {
  children?:
    | React.ReactNode
    | ((value: UseSettingsProviderReturn) => React.ReactNode);
}

export const SettingsProvider = ({
  children,
  ...rest
}: SettingsProviderProviderProps) => {
  const value = useSettingsProviderValue(rest);

  return (
    <SettingsProviderContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </SettingsProviderContext.Provider>
  );
};
