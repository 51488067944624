import {
  EventType,
  ParsedEvent as SharedParsedEvents,
  RawEvent as SharedRawEvents,
} from '@shared/models/events';
import { ParsedProduct } from '@shared/models/products';
import { CollectionReference, collection } from 'firebase/firestore';

import { themeColors } from '@theme/theme';
import { firestore } from '@utils/firebase';

export const eventTypeSolidColorMap: Record<EventType, string> = {
  trial: themeColors.darkYellow,
  product: themeColors.darkBlue,
  reschedule: themeColors.darkBlue,
  makeup: themeColors.darkPurple,
  recital: themeColors.darkBlue,
  other: themeColors.darkGray,
};

export const eventTypeLightColorMap: Record<EventType, string> = {
  trial: themeColors.lightYellow,
  product: themeColors.lightBlue,
  reschedule: themeColors.lightBlue,
  makeup: themeColors.lavender,
  recital: themeColors.lightBlue,
  other: themeColors.lightGray,
};

interface EventDetail {
  type: EventType;
  label: string;
  canRepeat: boolean;
  hasProduct: boolean;
  repeatByDefault?: boolean;
}

export const eventDetails: Record<EventType, EventDetail> = {
  trial: {
    type: 'trial',
    label: 'Trial Lesson',
    canRepeat: false,
    hasProduct: true,
  },
  product: {
    type: 'product',
    label: 'Private Lesson',
    canRepeat: true,
    repeatByDefault: true,
    hasProduct: true,
  },
  reschedule: {
    type: 'reschedule',
    label: 'Reschedule Lesson',
    canRepeat: false,
    hasProduct: true,
  },
  makeup: {
    type: 'makeup',
    label: 'Makeup Lesson',
    canRepeat: false,
    hasProduct: true,
  },
  recital: {
    type: 'recital',
    label: 'Recital',
    canRepeat: false,
    hasProduct: false,
  },
  other: {
    type: 'other',
    label: 'Event',
    canRepeat: true,
    repeatByDefault: false,
    hasProduct: false,
  },
};

export const isEventType = (type: string | undefined): type is EventType =>
  Boolean(type && type in eventDetails);

export const eventTypeTypeEntires = Object.entries(eventDetails).map(
  ([type, { label }]) => [type, label] as [EventType, string]
);

export const getMultiTypeEntries = (products?: ParsedProduct[]) =>
  eventTypeTypeEntires.flatMap(
    ([type, label]): [EventType | string, string][] =>
      type === 'product'
        ? (products || []).map(({ id, productName }) => [id, productName || ''])
        : [[type, label]]
  );

export type RawEvent = SharedRawEvents;
export type ParsedEvent = SharedParsedEvents;

export const eventsCollection = collection(
  firestore,
  'events'
) as CollectionReference<RawEvent>;
