import { Settings } from '@mui/icons-material';
import Badge, { BadgeProps } from '@mui/joy/Badge';

interface AvatarBadgeProps extends BadgeProps {
  isPrimary?: boolean;
  iconSize?: number;
}

export const AvatarBadge = ({
  isPrimary,
  size,
  ...props
}: AvatarBadgeProps) => {
  const iconSize = size === 'sm' ? 12 : 16;

  return (
    <Badge
      badgeContent={
        isPrimary ? (
          <Settings
            sx={{
              width: iconSize,
              height: iconSize,
            }}
          />
        ) : (
          0
        )
      }
      sx={(theme) => ({
        '& .MuiBadge-badge': {
          padding: 0,
          backgroundColor: theme.palette.neutral[400],
        },
      })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeInset="14%"
      size={size}
      {...props}
    />
  );
};
