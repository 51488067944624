import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Suspense, lazy } from 'react';

import { RouteLoader } from '@components/RouteLoader';

const RawPrivacyPolicy = lazy(() => import('./RawPrivacy'));

interface PrivacyPolicyProps {
  onDone: () => void;
}

const PrivacyPolicy = ({ onDone }: PrivacyPolicyProps) => (
  <>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton color="dark" />
        </IonButtons>
        <IonTitle>Privacy Policy</IonTitle>
        <IonButtons slot="end">
          <IonButton color="dark" onClick={onDone}>
            Done
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding" color="light">
      <Suspense fallback={<RouteLoader />}>
        <RawPrivacyPolicy />
      </Suspense>
    </IonContent>
  </>
);
export default PrivacyPolicy;
