import { useIonRouter } from '@ionic/react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/joy';
import { Popover, useMediaQuery } from '@mui/material';
import {
  atCircleOutline,
  iceCreamOutline,
  sparklesOutline,
} from 'ionicons/icons';
import { lazy } from 'react';

import { useFeatureFlags } from '@components/FeatureFlags/useFeatureFlags';
import ProfileAvatar from '@components/ProfileAvatar';
import { useTabs } from '@components/Tabs/tabs.routes';
import { useUserData } from '@components/UserData/useUserData';
import { getContactName } from '@features/Contacts/ContactDetail/ViewContact';
import { useFilterPopover } from '@features/Contacts/useFilterPopover';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';
import Settings from '@features/Settings';
import { useContactsQuery } from '@models/contacts/useContactsQuery';
import { signOut } from '@utils/firebase';

import GenericSidebar, { genericSidebarWidth } from './GenericSidebar';

const ThemeButtons = lazy(() => import('@components/Theme/ThemeButtons'));
const ThemeColors = lazy(() => import('@components/Theme/ThemeColors'));
const ThemeTypography = lazy(() => import('@components/Theme/ThemeTypography'));

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  desktopIosIcon?: string;
  desktopMdIcon?: string;
  title: string;
  className?: string;
  selected?: string[];
  hotkeys?: { first: string; second: string };
}

export const themePages: (AppPage & {
  Component: React.LazyExoticComponent<() => JSX.Element>;
})[] = [
  {
    title: 'Colors',
    url: '/theme/colors',
    iosIcon: sparklesOutline,
    mdIcon: sparklesOutline,
    Component: ThemeColors,
  },
  {
    title: 'Typography',
    url: '/theme/typography',
    iosIcon: atCircleOutline,
    mdIcon: atCircleOutline,
    Component: ThemeTypography,
  },
  {
    title: 'Buttons',
    url: '/theme/buttons',
    iosIcon: iceCreamOutline,
    mdIcon: iceCreamOutline,
    Component: ThemeButtons,
  },
];

export const sidebarWidth = genericSidebarWidth;

interface SidebarProps {
  showSidebar: boolean;
  setShowSidebar: (showSidebar: boolean) => void;
}

const Sidebar = ({ setShowSidebar, showSidebar }: SidebarProps) => {
  const organizationSlug = useOrganizationSlug();
  const tabs = useTabs();
  const { userContact, settingsIsOpen, setSettingsIsOpen } = useUserData();
  useContactsQuery();
  const [{ allSidebarItemsEnabled, viewThemeEnabled }] = useFeatureFlags();

  const isDesktop = useMediaQuery('(min-width:747px)');
  const router = useIonRouter();
  const {
    ref: buttonRef,
    filterIsOpen,
    setFilterIsOpen,
    anchorPosition,
  } = useFilterPopover();

  const appPages: AppPage[] = tabs.map((tab) => ({
    ...tab,
    className: 'tab-menu-item',
  }));

  const lists = [
    {
      title: '',
      items: appPages,
    },
    {
      title: 'Theme',
      items: themePages,
    },
  ];

  if (!showSidebar) {
    return null;
  }

  return (
    <>
      <GenericSidebar
        lists={lists.filter(
          ({ title }) =>
            (title !== 'More' || allSidebarItemsEnabled) &&
            (title !== 'Theme' || viewThemeEnabled)
        )}
      >
        <Box
          sx={{
            marginTop: 'auto',
            padding: '1em',
          }}
        >
          {isDesktop ? null : (
            <Button
              color="neutral"
              variant="plain"
              ref={buttonRef}
              onClick={() => {
                if (isDesktop) {
                  setFilterIsOpen(true);
                } else {
                  setSettingsIsOpen(true);
                }
              }}
            >
              <ProfileAvatar
                size="sm"
                sx={{ width: 50, height: 50, marginRight: '1em' }}
              />{' '}
              {userContact ? (
                <Typography level="body-md" sx={{ fontWeight: 400 }}>
                  {getContactName(userContact, undefined)}
                </Typography>
              ) : null}
            </Button>
          )}
        </Box>
        <Settings
          presentingPage={null}
          isOpen={settingsIsOpen}
          setIsOpen={setSettingsIsOpen}
        />
        <Popover
          open={filterIsOpen}
          onClose={() => setFilterIsOpen(false)}
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <List size="sm">
            {[
              {
                title: 'Preferences',
                onClick: () => {
                  setFilterIsOpen(false);
                  router.push(
                    `/${organizationSlug}/settings/preferences`,
                    'none'
                  );
                },
              },
              {
                title: 'Workspace settings',
                onClick: () => {
                  setFilterIsOpen(false);
                  router.push(`/${organizationSlug}/settings/general`, 'none');
                },
              },
              { title: 'Invite and manage members' },
              { title: 'Download App' },
              {
                title: 'Switch Organization',
                onClick: () => {
                  setFilterIsOpen(false);
                  setSettingsIsOpen(true);
                },
              },
              { title: 'Sign Out', onClick: signOut },
            ].map(({ title, onClick }) => (
              <ListItem key={title}>
                <ListItemButton
                  variant="plain"
                  color="neutral"
                  {...(onClick && { onClick })}
                >
                  {title}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Popover>
      </GenericSidebar>
    </>
  );
};

export default Sidebar;
