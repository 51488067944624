import {
  ParsedAssignment,
  ParsedAssignmentSection,
} from '@shared/models/assignmentItems';
import uuid4 from 'uuid4';

import { serverTimestamp } from '@utils/serverTimestamp';

export const initAssignment = ({
  ...overrides
}: Omit<
  ParsedAssignment,
  | 'type'
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'depth'
  | 'isSubtask'

  // optional fields
  | 'title'
  | 'description'
  | 'emoji'
> &
  Partial<
    Pick<ParsedAssignment, 'title' | 'description' | 'emoji'>
  >): ParsedAssignment => ({
  ...overrides,
  type: 'assignment',
  id: uuid4(),
  createdAt: serverTimestamp(),
  updatedAt: serverTimestamp(),
  depth: 1,
  isSubtask: false,
  title: overrides.title || '',
  description: overrides.description || '',
  emoji: overrides.emoji || '',
});

export const initAssignmentSection = (
  overrides: Omit<
    ParsedAssignmentSection,
    | 'depth'
    | 'isSubtask'
    | 'parentId'
    | 'sectionId'
    | 'id'
    | 'type'
    | 'createdAt'
    | 'updatedAt'
    | 'title'
  >
): ParsedAssignmentSection => {
  const id = uuid4();

  return {
    type: 'section',
    id,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    title: '',
    parentId: null,
    depth: 0,
    sectionId: id,
    isSubtask: false,
    ...overrides,
  };
};
